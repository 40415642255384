<script setup>
import Spinner from "@/assets/icons/SpinnerLoad.vue";
import { defineProps, ref } from "vue";

const props = defineProps({
  headers: {
    type: Object,
  },
  sortFuncion: {
    type: Function,
  },
  hideActions: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  noHaveData: {
    type: Boolean,
    default: false,
  },
  pagination: {
    type: Object,
    default: () => {},
  },
  havePagination: {
    type: Boolean,
    default: true,
  },
  heightTable: {
    type: String,
    default: "",
  },
  title: {
    type: String,
  },
});
const activeSortState = ref({
  dataName: null,
  order: null
});
const handleSort = (sortParams) => {
  // If sorting the same column, toggle between asc and desc
  if (activeSortState.value.dataName === sortParams.value) {
    activeSortState.value.order = 
      activeSortState.value.order === 'asc' ? 'desc' : 'asc';
  } else {
    // If sorting a different column, set new active state
    activeSortState.value = {
      dataName: sortParams.value,
      order: sortParams.order
    };
  }

  // Call the original sort function
  props.sortFuncion(sortParams);
};
</script>
<template>
  <div class="table-container">
  <table class="table">
    <thead>
      <tr>
        <th
          v-for="(head, index) in props.headers"
          :key="index"
          scope="col"
          class="p-3"
          :class="head.width"
        >
          <div class="head-container">
            <p>{{ head.title }}</p>
            <div
              v-if="head.dataName"
              class="icons-container"
            >
              <i
                @click="handleSort({order:'asc',value:head.dataName})"
                class="fa fa-caret-up"
                :class="{ 
                  'active-sort': activeSortState.dataName === head.dataName && activeSortState.order === 'asc' 
                }"
                aria-hidden="true"
              ></i>
              <i
                @click="handleSort({order:'desc',value:head.dataName})"
                class="fa fa-caret-down"
                :class="{ 
                  'active-sort': activeSortState.dataName === head.dataName && activeSortState.order === 'desc' 
                }"
                aria-hidden="true"
              ></i>
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <template v-if="props.isLoading">
        <tr>
          <td
            class="bg-white py-3 text-center text-sm m-auto"
            :colspan="props.headers?.length"
          >
            <Spinner />
          </td>
        </tr>
      </template>

      <template v-else-if="props.noHaveData">
        <tr>
          <td
            class="bg-white py-3 text-center text-sm"
            :colspan="props.headers?.length"
            v-text="'No hay datos disponible'"
          />
        </tr>
      </template>

      <template v-else>
        <slot name="body" />
      </template>
    </tbody>
  </table>
</div>
</template>
<style scoped>
.head-container {
  display: flex;
  gap: 8px;
  /* justify-content: center; */
  align-items: center;
  min-height: 65px;
}
.icons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0px;
  color: rgb(158, 158, 158);
}

.icons-container i {
  cursor: pointer;
  font-size: 12px;
}
.head-container p {
  font-size: 14px;
  margin: 0;
}
.icons-container i.active-sort {
  color: rgb(51, 51, 51); /* Change to your desired active color */
  font-weight: bold;
}
/* Sticky first column styling */
.first-column {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: white; /* Ensure it covers underlying content */
  border-right: 1px solid #e0e0e0; /* Optional: add a subtle border */
}

/* Ensure first column in body rows also follows the sticky behavior */
tbody tr td:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: white;
  border-right: 1px solid #e0e0e0;
}
</style>
