<template>
  <div class="content">
    <form @submit.prevent="submitForm">
      <div class="form-group">
        <TextField
          id="productTitle"
          v-model="store.currentProduct.title"
          :errorMessage="
            handlerValidate?.['title']?.$errors?.length > 0
              ? 'Este campo es requerido'
              : undefined
          "
          name="productTitle"
          :label="'Nombre del Producto'"
        />
      </div>

      <div class="form-group">
        <label for="productDescription">Descripción del Producto</label>
        <textarea
          :class="
            handlerValidate?.['description']?.$errors?.length > 0
              ? 'form-error-input'
              : ''
          "
          class="form-control"
          id="productDescription"
          v-model="store.currentProduct.description"
          rows="3"
        ></textarea>
        <div class="textField">
          <p
            class=" "
            v-if="handlerValidate?.['description']?.$errors?.length > 0"
            v-text="'Este campo es requerido'"
          />
        </div>
      </div>
      <div class="form-group">
        <TextField
          id="productPrice"
          v-model="store.currentProduct.price"
          :errorMessage="getPriceErrorMessage()"
          name="productPrice"
          :label="'Precio del Producto (USD)'"
        />
      </div>
      <div class="form-group">
        <label class="mb-2" for="productCategory">Categoría del Producto</label>
        <div class="row gap-3 check-form">
          <CheckBoxInput
            class="gap-1 col-12 col-sm-6"
            v-for="(category, index) in categories"
            :key="category.id"
            :value="category.id"
            :for="`${index}`"
            :text="category.name"
            @change="checkOptionUpdate(category.id)"
          />
        </div>
      </div>

      <div class="form-group pt-4 pb-2">
        <label class="style-check font-medium text-tertiary">
          <input type="checkbox" v-model="susc" />
          <span class="checkmark checkmark__blue" />
          <span v-text="'Producto de  suscripción '" />
        </label>
      </div>

      <div class="form-group">
        <label for="url">Url del curso</label>
        <input
          type="url"
          name="url"
          class="form-control"
          v-model="store.currentProduct.external_url"
          id="url"
          placeholder="https://example.com"
          pattern="https://.*"
        />

        <div class="textField">
          <p
            v-if="showError"
            class="error-message"
            v-text="'Por favor, ingresa una URL válida.'"
          />
        </div>
      </div>

      <!-- Portrait Image Upload -->
      <div class="form-group">
        <label class="mb-2" for="productPortrait">Imagen del producto</label>
        <div class="d-flex align-items-center">
          <input
            type="file"
            class="form-control-file"
            id="productPortrait"
            @change="uploadPortraitImage($event)"
            accept="image/*"
          />
          <span class="ms-2">Subir Imagen</span>
        </div>
      </div>

      <div id="modulesContainer">
        <h4 class="mt-4">Módulos</h4>
        <div
          class="card"
          v-for="(module, moduleIndex) in store.currentProduct.modules"
          :key="moduleIndex"
          :id="'module' + (moduleIndex + 1)"
        >
          <div class="card-body">
            <h5 class="card-title">Módulo {{ moduleIndex + 1 }}</h5>
            <div class="form-group">
              <label :for="'moduleTitle' + (moduleIndex + 1)"
                >Título del Módulo</label
              >
              <input
                type="text"
                class="form-control"
                :id="'moduleTitle' + (moduleIndex + 1)"
                v-model="module.title"
                required
              />
            </div>
            <div :id="'lessonsContainer' + (moduleIndex + 1)">
              <h6>Lecciones</h6>
              <div
                class="card mb-4"
                v-for="(lesson, lessonIndex) in module.lessons"
                :key="lessonIndex"
                :id="'lesson' + (moduleIndex + 1) + '_' + (lessonIndex + 1)"
              >
                <div class="card-body">
                  <h6 class="card-title">Lección {{ lessonIndex + 1 }}</h6>
                  <div class="form-group">
                    <label
                      :for="
                        'lessonTitle' +
                        (moduleIndex + 1) +
                        '_' +
                        (lessonIndex + 1)
                      "
                      >Título de la lección</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      :id="
                        'lessonTitle' +
                        (moduleIndex + 1) +
                        '_' +
                        (lessonIndex + 1)
                      "
                      v-model="lesson.title"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label
                      :for="
                        'lessonContent' +
                        (moduleIndex + 1) +
                        '_' +
                        (lessonIndex + 1)
                      "
                      >Contenido de la Lección</label
                    >
                    <quill-editor
                      v-model:value="
                        store.currentProduct.modules[moduleIndex].lessons[
                          lessonIndex
                        ].content
                      "
                      :ref="quillEditorRef"
                      :options="state.editorOption"
                      class="quill-editor"
                      id="ql-editor"
                      @change="onEditorChange($event, moduleIndex, lessonIndex)"
                    />
                  </div>
                  <div class="form-group">
                    <label
                      class="mb-2"
                      :for="
                        'lessonVideo' +
                        (moduleIndex + 1) +
                        '_' +
                        (lessonIndex + 1)
                      "
                      >Subir Media</label
                    >
                    <FileUpload
                      @file-selected="uploadVideo(
                        $event,
                        moduleIndex,  
                        lessonIndex)"
                    />
                  </div>
                  <button
                    type="button"
                    class="btn btn-danger"
                    @click="removeLesson(moduleIndex, lesson._id, lessonIndex)"
                  >
                    Eliminar lección
                  </button>
                </div>
              </div>
            </div>
            <button
              type="button"
              class="btn btn-secondary mt-2 mx-2"
              @click="addLesson(moduleIndex)"
            >
              Añadir Lección
            </button>
            <button
              type="button"
              class="btn btn-danger mt-2"
              @click="removeModule(module?._id, moduleIndex)"
            >
              Eliminar Módulo
            </button>
          </div>
        </div>
      </div>
      <button
        type="button"
        class="btn btn-secondary mt-4 mx-2"
        @click="addModule"
      >
        Agregar Modulo
      </button>
      <button type="submit" class="btn btn-primary mt-4">Subir</button>
    </form>
    <div v-if="showConfirmDialog" class="modal" style="display: block">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Confirmar Eliminación</h5>
            <button
              type="button"
              class="btn-close"
              @click="cancelDelete"
            ></button>
          </div>
          <div class="modal-body">
            <p>
              ¿Estás seguro de que deseas eliminar este elemento? Esta acción no
              se puede deshacer.
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="cancelDelete"
            >
              Cancelar
            </button>
            <button type="button" class="btn btn-danger" @click="confirmDelete">
              Eliminar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showConfirmDialog" class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
import "quill/dist/quill.snow.css";
import { showNotification } from "@/composables/useNotification";
import router from "@/router";
import useVuelidate from "@vuelidate/core";
import { required, numeric } from "@vuelidate/validators";
import axios from "axios";
import { onMounted, reactive, ref } from "vue";
import { useProductStore } from "../stores/productStore";
import { useAuth } from "@/composables/useAuth";
import CheckBoxInput from "./common/CheckInput.vue";
import TextField from "./common/TextField.vue";
import { quillEditor } from "vue3-quill";
import FileUpload from "./forms/FileUpload.vue";

export default {
  name: "ProductForm",
  components: {
    TextField,
    CheckBoxInput,
    quillEditor,
    FileUpload
  },
  setup() {
    const store = useProductStore();
    const { getToken } = useAuth();
    const categories = ref([]);
    const susc = ref(false);
    const showError = ref(false);
    const showConfirmDialog = ref(false);
    const pendingDeleteAction = ref(null);
    const state = reactive({
      content: "",
      _content: "",
      editorOption: {
        debug: "info",
        placeholder: "Escribe el contenido de la lección",
        readonly: false,
        theme: "snow",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link"],
            [
              { align: "" },
              { align: "center" },
              { align: "right" },
              { align: "justify" },
            ],
            ["clean"],
            ["code-block"],
          ],
        },
      },
      disabled: false,
    });
    const onEditorChange = (a, b, c) => {
      store.currentProduct.modules[b].lessons[c].content = a.html;
    };
    const handlerValidate = useVuelidate(
      {
        title: {
          required,
        },
        description: {
          required,
        },
        price: {
          required,
          numeric,
        },
      },
      store.currentProduct
    );
    /* const handleFileSelected = (file) => {
      store.currentProduct.modules[moduleIndex].lessons[lessonIndex].videoFile =
        file;
    }; */
    const fetchAndSetCategories = async () => {
      await store.fetchCategories();
      categories.value = store.categories;
    };

    const checkOptionUpdate = (event) => {
      const index = store.currentProduct.categories.indexOf(event);
      if (index !== -1) {
        store.currentProduct.categories.splice(index, 1);
      } else {
        store.currentProduct.categories.push(event);
      }
    };

    function findValue(obj, targetValue) {
      return Object.values(obj).some(
        (value) =>
          value === targetValue ||
          (typeof value === "object" &&
            value !== null &&
            findValue(value, targetValue))
      );
    }

    const addModule = () => store.addModule();

    const removeModule = async (moduleIndex) => {
      pendingDeleteAction.value = {
        type: "module",
        moduleIndex,
      };
      showConfirmDialog.value = true;
    };

    const addLesson = (moduleIndex) => store.addLesson(moduleIndex);

    const removeLesson = async (moduleIndex, lessonIndex, localIndex) => {
      pendingDeleteAction.value = {
        type: "lesson",
        moduleIndex,
        lessonIndex,
        localIndex,
      };
      showConfirmDialog.value = true;
    };

    const cancelDelete = () => {
      showConfirmDialog.value = false;
      pendingDeleteAction.value = null;
    };

    const confirmDelete = async () => {
      if (pendingDeleteAction.value.type === "lesson") {
        if (
          findValue(
            store.currentProduct.modules,
            pendingDeleteAction.value.lessonIndex
          )
        ) {
          await store.removeLesson(
            pendingDeleteAction.value.moduleIndex,
            pendingDeleteAction.value.lessonIndex
          );
        } else {
          await store.removeLesson(
            pendingDeleteAction.value.moduleIndex,
            pendingDeleteAction.value.lessonIndex,
            pendingDeleteAction.value.localIndex
          );
        }
        showConfirmDialog.value = false;
      } else if (pendingDeleteAction.value.type === "module") {
        if (
          findValue(
            store.currentProduct.modules,
            pendingDeleteAction.value.moduleIndex
          )
        ) {
          await store.removeModule(pendingDeleteAction.value.moduleIndex);
        }
        showConfirmDialog.value = false;
      }
    };

    const uploadPortraitImage = (event) => {
      const file = event.target.files[0];
      store.currentProduct.portraitImage = file;
    };

    const uploadVideo = async (event, moduleIndex, lessonIndex) => {
      store.currentProduct.modules[moduleIndex].lessons[lessonIndex].videoFile =
        event;
    };

    const submitForm = async () => {
      const submitButton = document.getElementsByClassName(
        "btn btn-primary mt-4"
      )[0];
      const _validate = await handlerValidate.value.$validate();
      if (!_validate) return;

      submitButton.classList.add("disabled");

      const formData = new FormData();
      formData.append("title", store.currentProduct.title);
      formData.append("description", store.currentProduct.description);
      formData.append("price", store.currentProduct.price);
      formData.append(
        "categories",
        JSON.stringify(store.currentProduct.categories)
      );
      formData.append("is_sub", susc.value);

      if (store.currentProduct.external_url) {
        formData.append("external_url", store.currentProduct.external_url);
      }

      if (store.currentProduct.portraitImage) {
        formData.append("portraitImage", store.currentProduct.portraitImage);
      }

      const modulesData = store.currentProduct.modules.map((module) => ({
        title: module.title,
        lessons: module.lessons.map((lesson) => ({
          title: lesson.title,
          content: lesson.content,
        })),
      }));
      console.log(store.currentProduct.modules);
      
      formData.append("modules", JSON.stringify(modulesData));
      store.currentProduct.modules.forEach((module) => {
        module.lessons.forEach((lesson) => {
          if (lesson.videoFile) {
            formData.append(
              `file_${module.title}_${lesson.title}`,
              lesson.videoFile
            );
          }
        });
      });

      try {
        const token = await getToken();
        if (!token) {
          router.push("/login");
          return;
        }

        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/products/`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 201) {
          showNotification("Producto creado", "success");
          setTimeout(() => {
            router.push({ name: "products" });
          }, 1500);
        }
      } catch (error) {
        showNotification("Algo ha ido mal", "error");
        console.error("Error creating product:", error);
        if (error.response?.status === 401) {
          router.push("/login");
        }
      }

      setTimeout(() => {
        submitButton.classList.remove("disabled");
      }, 1600);
    };

    const getPriceErrorMessage = () => {
      const errors = handlerValidate.value.price.$errors;
      if (errors.length > 0) {
        if (errors[0].$validator === "required") {
          return "Este campo es requerido";
        }
        if (errors[0].$validator === "nonNegative") {
          return "El precio no puede ser negativo";
        }
        if (errors[0].$validator === "numeric") {
          return "El precio debe ser un número válido";
        }
      }
      return undefined;
    };

    onMounted(async () => {
      await fetchAndSetCategories();
    });

    return {
      categories,
      addModule,
      removeModule,
      addLesson,
      removeLesson,
      uploadVideo,
      submitForm,
      uploadPortraitImage,
      handlerValidate,
      checkOptionUpdate,
      getPriceErrorMessage,
      susc,
      showError,
      cancelDelete,
      confirmDelete,
      showConfirmDialog,
      store,
      onEditorChange,
      state,
    };
  },
};
</script>

<style scoped>
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  background-color: #2b2b2b;
  color: white;
}

.ql-container {
  color: white;
}

.ql-editor {
  color: white;
}

.ql-editor p {
  color: white !important;
}

.ql-editor span {
  color: white !important;
}
.quill-editor {
  height: 300px;
}
.quill-editor::placeholder {
  color: white !important;
  opacity: 1; /* Firefox */
}

.quill-editor::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: white !important;
}
.course-form {
  max-width: 600px;
  margin: 0 auto;
}
.form-group {
  margin-bottom: 20px;
}
label {
  font-weight: 500;
}
.textField {
  position: relative;
  width: 100%;
}
.textField p {
  color: red;
  position: absolute;
  font-size: 12px;
  margin-top: 1px;
}
.textField span {
  font-weight: 500;
}
.form-error-input {
  border: 1px solid red;
}

@media (max-width: 767px) {
  .check-form .col-sm-6 {
    flex: 0 0 auto;
    width: 100%;
  }
}
</style>
