<template>
  <div class="w-100">
    <div v-if="loadingItem == item.id">
      <SpinnerLoad />
    </div>
    <div v-if="isEditing && loadingItem !== item.id">
      <input
        type="number"
        class="form-control input-editable"
        v-model="updatedPercentage"
        @blur="emitUpdate"
        @keyup.enter.prevent="emitUpdate"
        ref="inputRef"
      />
    </div>
    <div
      v-else-if="!isEditing && loadingItem !== item.id"
      class="editable-containter"
    >
      % {{ item.percentage || "-" }}
      <div @click="enableEditing" class="editable-button">
        <i class="fa fa-pencil" aria-hidden="true"></i>
      </div>
    </div>
  </div>
</template>

<script setup>
import SpinnerLoad from "@/assets/icons/SpinnerLoad.vue";
import { defineEmits, defineProps, ref, nextTick, watch } from "vue";
import { toast } from 'vue3-toastify'

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  loadingItem: {
    type: Number,
  },
});
const inputRef = ref(null);
const isEditing = ref(false);
const updatedPercentage = ref(props.item.percentage);
const originalPercentage = ref(props.item.percentage);
const emit = defineEmits(["update-item"]);
const isUpdating = ref(false);
const enableEditing = async () => {
  isEditing.value = true;
  originalPercentage.value = props.item.percentage;
  updatedPercentage.value = props.item.percentage;
  await nextTick();
  inputRef.value.focus();
};

/* const blur = async () => {
  
  if (updatedPercentage.value !== props.item.percentage) {
    const updatedItem = {
      ...props.item,
      percentage: updatedPercentage.value,
    };
    const success = await emit(
      "update-item",
      updatedItem,
      originalPercentage.value
    );
    if (!success) {
      updatedPercentage.value = originalPercentage.value;
    }
  }
  isEditing.value = false;
}; */
const emitUpdate = async () => {
  if(updatedPercentage.value < 0 || updatedPercentage.value > 100) {
    toast.error("El porcentaje debe ser entre 0 y 100");
    isUpdating.value = false;
    isEditing.value = false;

    return;
  } 
  if (isUpdating.value) return;
  try {
    isUpdating.value = true;
    if (updatedPercentage.value !== props.item.percentage) {
      const updatedItem = {
        ...props.item,
        percentage: updatedPercentage.value,
      };
      const success = await emit(
        "update-item",
        updatedItem,
        originalPercentage.value
      );
      if (!success) {
        updatedPercentage.value = originalPercentage.value;
      }
    }
  } finally {
    isUpdating.value = false;
    isEditing.value = false;
  }
};

watch(
  () => props.item.percentage,
  (newValue) => {
    if (!isEditing.value) {
      updatedPercentage.value = newValue;
      originalPercentage.value = newValue;
    }
  }
);
</script>
<style scoped>
.input-editable {
  max-height: 30px;
}
.editable-containter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  width: 100%;
  min-width: 80px;
}
.editable-button {
  border-radius: 8px;
  background: #222222;
  padding: 4px 8px;
  color: white;
  font-size: 12px;
  cursor: pointer;
}
.w-100 {
  width: 100%;
  max-width: 72px;
}
</style>
