<template>
  <div class="advanced-file-upload">
    <div
      class="file-upload-container"
      :class="{ 'is-uploading': isUploading, 'has-file': file }"
    >
      <input
        type="file"
        :id="inputId"
        @change="handleFileSelect"
        :accept="acceptedFileTypes"
        class="file-input"
      />

      <label :for="inputId" class="file-upload-label">
        <div v-if="!file" class="default-state">
          <span class="upload-icon"
            ><i class="fa fa-cloud-upload" aria-hidden="true"></i
          ></span>
          <span class="upload-text">Subir Video</span>
        </div>

        <div v-else-if="isUploading" class="uploading-state">
          <div class="file-info">
            <span class="file-name">{{ truncateFilename(file.name) }}</span>
          </div>

          <div class="progress-container">
            <div
              class="progress-bar"
              :style="{ width: `${uploadProgress}%` }"
            ></div>
          </div>

          <div class="upload-status">
            <div>
              <span class="upload-text">{{ uploadStatusText }}</span>
              <span class="upload-percentage">{{ uploadProgress }}%</span>
            </div>
            <span class="file-size">{{ formatFileSize(file.size) }}</span>
          </div>
        </div>

        <div v-else-if="file" class="file-selected-state">
          <div class="uploaded-file">
            <span class="file-name">{{ truncateFilename(file.name) }}</span>
            <span class="file-size">{{ formatFileSize(file.size) }}</span>
          </div>
          <button @click.prevent="clearFile" class="clear-button">✖</button>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";

export default {
  name: "AdvancedFileUpload",
  props: {
    placeholder: {
      type: String,
      default: "Subir Video",
    },
    acceptedFileTypes: {
      type: String,
      default: "video/*",
    },
    maxFileSize: {
      type: Number,
      default: 100 * 1024 * 1024, // 100MB
    },
  },
  emits: ["file-selected", "file-upload-complete", "file-upload-error"],
  setup(props, { emit }) {
    const file = ref(null);
    const isUploading = ref(false);
    const uploadProgress = ref(0);
    const inputId = `file-upload-${Math.random().toString(36).substr(2, 9)}`;

    const uploadStatusText = computed(() => {
      return uploadProgress.value === 100 ? "Completado" : "Subiendo...";
      /* if (isUploading.value) {
        }
        return ''; */
    });

    const handleFileSelect = (event) => {
      const selectedFile = event.target.files[0];

      if (selectedFile) {
        // Validate file size
        if (selectedFile.size > props.maxFileSize) {
          emit("file-upload-error", "File size exceeds maximum limit");
          return;
        }

        file.value = selectedFile;
        emit("file-selected", selectedFile);

        // Simulate upload progress (replace with actual upload logic)
        simulateUpload(selectedFile);
      }
    };

    const simulateUpload = (fileToUpload) => {
      isUploading.value = true;
      uploadProgress.value = 0;

      const interval = setInterval(() => {
        uploadProgress.value += 9;

        if (uploadProgress.value >= 100) {
          clearInterval(interval);
          isUploading.value = false;
          emit("file-upload-complete", fileToUpload);
        }
      }, 100);
    };
    function truncateFilename(filename) {
      const nameParts = filename.split(".");
      const extension = nameParts.pop();
      const baseName = nameParts.join(".");
      if (baseName.length <= 14) {
        return filename;
      }
      const truncatedName = `${baseName.slice(0, 12)}...${baseName.slice(-4)}`;
      return `${truncatedName}.${extension}`;
    }

    const clearFile = () => {
      file.value = null;
      isUploading.value = false;
      uploadProgress.value = 0;
      // Reset file input
      const input = document.getElementById(inputId);
      if (input) input.value = "";
    };

    const formatFileSize = (bytes) => {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const sizes = ["Bytes", "KB", "MB", "GB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(1)) + " " + sizes[i];
    };

    return {
      file,
      isUploading,
      uploadProgress,
      inputId,
      handleFileSelect,
      clearFile,
      formatFileSize,
      uploadStatusText,
      truncateFilename,
    };
  },
};
</script>

<style scoped>
.uploaded-file{
    display: flex;
    justify-content: space-between;
    gap: 8px;
}
.advanced-file-upload {
  width: 100%;
  max-width: 400px;
}

.file-upload-container {
  position: relative;
  border: 2px dashed #ccc;
  border-radius: 8px;
  transition: all 0.3s ease;
}



.file-input {
  display: none;
}

.file-upload-label {
  display: block;
  padding: 15px;
  cursor: pointer;
  text-align: center;
}

.default-state {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-icon {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.uploading-state {
  text-align: left;
}

.file-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.progress-container {
  width: 100%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px;
}

.progress-bar {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.3s ease;
}

.upload-status {
  display: flex;
  justify-content: space-between;
}

.file-selected-state {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clear-button {
  background: none;
  border: none;
  color: rgb(207, 34, 34);
  font-size: 1.2rem;
  cursor: pointer;
}

.file-name {
  font-weight: bold;
}

.file-size {
  color: #666;
}
</style>
