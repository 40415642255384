<script setup>
import { ref, onMounted } from "vue";
import { useReportsStore } from "../stores/Report";
import PaginationControl from "@/components/common/PaginationControl.vue";
import { useRouter } from 'vue-router';

const router = useRouter();
const reportsStore = useReportsStore();
const isLoading = ref(false);
const currentPage = ref(1);

const formatDateDisplay = (dateString) => {
  return dateString 
  //const cleanDateString = dateString.replace(/\*/g, "");
 /*  const date = new Date(cleanDateString);
  const months = [
    "Ene", "Feb", "Mar", "Abr", "May", "Jun",
    "Jul", "Ago", "Sep", "Oct", "Nov", "Dic",
  ];
  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month} ${day}, ${year}` */
};

const handlePageChange = async (page) => {
  currentPage.value = page;
  await reportsStore.getReports(page);
};

onMounted(async () => {
  isLoading.value = true;
  const token = sessionStorage.getItem('token');
  if (!token) {
    router.push('/login');
    return;
  }

  try {
    await reportsStore.getReports(currentPage.value);
  } catch (error) {
    console.error('Error fetching reports:', error);
    if (error.response?.status === 401) {
      router.push('/login');
    }
  } finally {
    isLoading.value = false;
  }
});
</script>

<template>
  <section>
    <h2 class="">Reportes</h2>
    <table class="w-full">
      <thead>
        <tr class="reporte-header">
          <th class="column">Período Semanal</th>
          <th class="column">Total</th>
          <th class="column">{{ " " }}</th>
        </tr>
      </thead>
      <tbody v-if="reportsStore.reports?.length">
        <tr
          v-for="(report, index) in reportsStore.reports"
          :key="index"
          :class="[
            index % 2 === 0 ? 'reporte-item' : 'reporte-it',
            'reporte-row',
          ]"
        >
          <td class="column">
            {{ `${formatDateDisplay(report.date_from)} - ${formatDateDisplay(report.date_to)}` }}
          </td>
          <td class="column">{{ report.total ? report.total : " ---" }}</td>
          <td class="column">
            <a class="search-button" :href="report.file" download>
              <i class="fa fa-download" aria-hidden="true"></i>
              Descargar reporte
            </a>
          </td>
        </tr>
      </tbody>
      <div v-else>
        <div class="selection-box">
          <h2>No hay datos disponibles</h2>
        </div>
      </div>
    </table>

    <!-- Add PaginationControl -->
    <PaginationControl
      v-if="reportsStore.reports?.length && reportsStore.pageCount > 1"
      :current-page="currentPage"
      :total-pages="reportsStore.pageCount"
      @page-change="handlePageChange"
    />
  </section>
</template>

<style scoped>
a {
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;
}

a:visited {
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;
}

tbody td {
  font-size: 16px;
  font-weight: 500;
}

.column {
  min-width: 208px;
}

.reporte-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px;
  margin-bottom: 8px;
}

.reporte-it {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px;
  background-color: #ececec;
  margin-bottom: 8px;
}

.reporte-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 6px 10px;
  border-bottom: 1px solid rgb(160, 160, 160);
}

.reporte-header th {
  font-size: 20px;
  font-weight: 500;
}

.reporte-header .column {
  min-width: 225px;
}

.reporte-row {
  display: flex;
  justify-content: space-between;
}

.search-button {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  background-color: #212429;
  color: #fff;
  border: none;
  padding-right: 24px;
  padding-left: 24px;
  padding-bottom: 6px;
  padding-top: 6px;
  border-radius: 6px;
}

.selection-box {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
}

.selection-box h2 {
  font-size: 28px;
}
</style>