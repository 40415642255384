<script setup>
import DataTable from "@/components/common/DataTable.vue";
import { showNotification } from "@/composables/useNotification";
import { useAffiliateStore } from "@/stores/Affiliate";
import { useOffersStore } from "@/stores/Offers";
import { useProductStore } from "@/stores/productStore";
import { getUserIdFromToken } from "@/utils/userInfo";
import { watch } from "vue";
import { computed, onMounted, ref, reactive } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const offersStore = useOffersStore();
const productStore = useProductStore();
const affiliateStore = useAffiliateStore();
const billerURL = process.env.VUE_APP_BILLER_URL;
const isProducer = process.env.VUE_APP_APP_TYPE === "skillper";
const utm = ref("");
const setUtm = () => {
  utm.value = affiliateStore._affiliateProducts[0]?.affiliate?.utm || "";
};
const selectedProduct = ref("");
const newOffer = reactive({
  productId: "",
  price: "",
  trialDays: "",
  periodicityDays: "",
});

const showModal = ref(false);
const isLoading = ref(true);

const titlesTable = isProducer
  ? computed(() => [
      {
        width: "w-3/12",
        title: "Producto",
      },
      {
        width: "w-2/12",
        title: "Precio Original",
      },
      {
        width: "w-2/12",
        title: "Precio Oferta",
      },
      {
        width: "w-2/12",
        title: "Días de Prueba",
      },
      {
        width: "w-2/12",
        title: "Periodicidad (Días)",
      },
      {
        width: "w-4/12",
        title: "Link de Oferta",
      },
      {
        width: "w-2/12",
        title: "",
      },
    ])
  : computed(() => [
      {
        width: "w-6/12",
        title: "Producto",
      },
      {
        width: "w-2/12",
        title: "Precio Original",
      },
      {
        width: "w-2/12",
        title: "Precio Oferta",
      },
      {
        width: "w-2/12",
        title: "Link de Oferta",
      },
    ]);
watch(() => affiliateStore._affiliateProducts, setUtm, { deep: true });
onMounted(async () => {
  const token = sessionStorage.getItem("token");
  if (!token) {
    router.push("/login");
    return;
  }

  const userId = getUserIdFromToken(sessionStorage.getItem("token"));
  if (!userId) {
    router.push("/login");
    return;
  }
  if (isProducer) {
    await offersStore.getOffers();
    await productStore.getAllProducts(userId);
  } else {
    await affiliateStore.getAffiliateProducts(userId);
    await offersStore.getAffiliateOffers();
  }
  isLoading.value = false;
});

const handleProductFilter = async (productId) => {
  isLoading.value = true;
  if (isProducer) {
    await offersStore.getOffers(productId || null);
  } else {
    await offersStore.getAffiliateOffers(productId || null);
  }
  isLoading.value = false;
};

const copyLink = (uuid) => {
  const link = isProducer ? `${billerURL}/checkout/${uuid}`:`${billerURL}/checkout/${uuid}?utm_affiliate=${utm.value}`
  navigator.clipboard
    .writeText(link)
    .then(() => {
      showNotification("Link copiado", "success");
    })
    .catch((err) => {
      console.error("Failed to copy link: ", err);
    });
};

const goToLink = (uuid) => {
  if(isProducer){
    const link = `${billerURL}/checkout/${uuid}`;
    window.open(link, "_blank");
    return;
  } else{
    const link = `${billerURL}/checkout/${uuid}?utm_affiliate=${utm.value}`;
    window.open(link, "_blank");
  }
};

const createOffer = async () => {
  if (!newOffer.productId || newOffer.price === "") {
    showNotification("Por favor, complete todos los campos", "error");
    return;
  }

  const selectedProduct = products.value.find(
    (p) => p.id === newOffer.productId
  );

  if (selectedProduct.is_sub) {
    if (newOffer.trialDays === "" || newOffer.periodicityDays === "") {
      showNotification("Por favor, complete todos los campos", "error");
      return;
    }
  } else {
    newOffer.trialDays = "0";
    newOffer.periodicityDays = "30";
  }

  try {
    await offersStore.createOffer({
      product: newOffer.productId,
      price: parseFloat(newOffer.price),
      trial_days: selectedProduct.is_sub ? parseInt(newOffer.trialDays) : 0,
      periodicity_days: selectedProduct.is_sub
        ? parseInt(newOffer.periodicityDays)
        : 30,
    });
    showNotification("Oferta creada exitosamente", "success");
    newOffer.productId = "";
    newOffer.price = "";
    newOffer.trialDays = "";
    newOffer.periodicityDays = "";
    showModal.value = false;
    await offersStore.getOffers();
  } catch (error) {
    console.error("Error creating offer:", error);
    showNotification("Error al crear la oferta", "error");
  }
};

const deleteOffer = async (uid) => {
  try {
    await offersStore.deleteOffer(uid);
    showNotification("Oferta eliminada exitosamente", "success");
    await offersStore.getOffers();
  } catch (error) {
    console.error("Error deleting offer:", error);
    showNotification("Error al eliminar la oferta", "error");
  }
};

const products = computed(() =>
  (
    (isProducer
      ? productStore.listProducts.products
      : affiliateStore.affiliateProducts) || []
  ).filter(
    (product) =>
      product.status === "aceptado" || product.status === "confirmado"
  )
);
</script>

<template>
  <section>
    <section class="mb-4">
      <div class="main-title">
        <h2 class="fw-semibold">{{ $t("MENU.OFFERS") }}</h2>
        <button
          v-if="isProducer"
          @click="showModal = true"
          class="btn btn-dark"
        >
          <i class="fas fa-plus"></i> Agregar nueva oferta
        </button>
      </div>
    </section>

    <!-- Product Filter -->
    <div class="mb-4" v-if="products.length">
      <p class="mb-0">Filtrar por producto</p>
      <select
        class="form-select"
        v-model="selectedProduct"
        @change="handleProductFilter(selectedProduct)"
      >
        <option value="">Todos los productos</option>
        <option
          v-for="product in products"
          :key="isProducer ? product.id : product.product.id"
          :value="isProducer ? product.id : product.product.id"
        >
          {{ isProducer ? product.title : product.product.title }}
        </option>
      </select>
    </div>
    <div class="mb-4" v-if="!products.length">
      <p class="mb-0">No hay productos disponibles</p>
    </div>

    <!-- Modal -->
    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <h3 class="modal-title">Nueva Oferta</h3>
        <div class="form-group">
          <label>Producto:</label>
          <select v-model="newOffer.productId" class="form-control">
            <option value="">Seleccione un producto</option>
            <option
              v-for="product in products"
              :key="product.id"
              :value="product.id"
            >
              {{ product.title }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label>Precio:</label>
          <input
            v-model="newOffer.price"
            type="number"
            step="0.01"
            class="form-control"
            placeholder="Ingrese el precio"
          />
        </div>
        <div
          class="form-group"
          v-if="products.find((p) => p.id === newOffer.productId)?.is_sub"
        >
          <label>Días de Prueba:</label>
          <input
            v-model="newOffer.trialDays"
            type="number"
            class="form-control"
            placeholder="Ingrese los días de prueba"
          />
        </div>
        <div
          class="form-group"
          v-if="products.find((p) => p.id === newOffer.productId)?.is_sub"
        >
          <label>Periodicidad de cobro:</label>
          <input
            v-model="newOffer.periodicityDays"
            type="number"
            class="form-control"
            placeholder="Ingrese la periodicidad en días"
          />
        </div>
        <div class="modal-actions">
          <button @click="createOffer" class="btn btn-dark">
            Crear Oferta
          </button>
          <button @click="showModal = false" class="btn btn-outline-danger">
            Cancelar
          </button>
        </div>
      </div>
    </div>

    <div class="table-container">
      <DataTable
        :noHaveData="offersStore?.data?.length === 0"
        :headers="titlesTable"
        :isLoading="isLoading"
      >
        <template #body>
          <tr v-for="(item, index) in offersStore?.data" :key="index">
            <td class="p-3" v-text="item.product.title || '-'" />
            <td class="p-3" v-text="item.product.price || '-'" />
            <td  class="p-3" v-text="item.price || '-'" />
            <td
              v-if="isProducer"
              class="p-3"
              v-text="item.product.is_sub ? item.trial_days : '-'"
            />
            <td
              v-if="isProducer"
              class="p-3"
              v-text="item.product.is_sub ? item.periodicity_days : '-'"
            />
            <td class="p-3 flex items-center">
              <button
                class="btn bg-green text-white mr-4"
                @click="goToLink(item.uid)"
              >
                Ir a oferta
              </button>
              <button
                class="btn bg-copy text-white min-w-[40px]"
                @click="copyLink(item.uid)"
              >
                <i class="fas fa-copy"></i>
              </button>
            </td>
            <td v-if="isProducer" class="p-3 deleteButton">
              <button
                v-if="item.principal"
                class="btn btn-secondary min-w-[120px]"
                disabled
              >
                Oferta Principal
              </button>
              <button
                v-else
                class="btn btn-outline-danger min-w-[120px]"
                @click="deleteOffer(item.uid)"
              >
                Eliminar oferta
              </button>
            </td>
          </tr>
        </template>
      </DataTable>
    </div>
  </section>
</template>

<style scoped>
.table-container {
  overflow-x: auto;
}

.flex.items-center {
  display: flex;
  align-items: center;
}

.mr-4 {
  margin-right: 1rem;
}

.main-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.bg-copy {
  background-color: transparent;
  border: 1px solid #ccc;
}

.btn {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  font-weight: bold;
  white-space: nowrap;
}

.btn-primary {
  background-color: #007bff;
  color: white;
  border: none;
}

.bg-copy:hover,
.bg-copy:hover i {
  background-color: #22c55e;
}

.btn-success {
  background-color: #28a745;
  color: white;
  border: none;
}

.btn-secondary {
  background-color: #6c757d;
  color: white;
  border: none;
  cursor: not-allowed;
}

.btn-outline-secondary {
  background-color: transparent;
  color: #6c757d;
  border: 1px solid #6c757d;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  width: 80%;
  max-width: 500px;
}

.modal-title {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
}

.form-control {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
}

.bg-copy i {
  color: #22c55e;
}

.deleteButton {
  align-items: start;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.mb-4 {
  margin-bottom: 1rem;
}
</style>
