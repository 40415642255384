export const getUserIdFromToken = (token) => {
  try {
    if (token) {
      const payload = JSON.parse(atob(token.split(".")[1]));
      return payload.sub;
    }
    return null;
  } catch (error) {
    console.error("Error getting user ID:", error);
    return null;
  }
};
