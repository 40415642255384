<script setup>
import DataTable from "@/components/common/DataTable.vue";
import TextField from "@/components/common/TextField.vue";
import ModalSales from "@/components/ModalSales.vue";
import PaginationControl from "@/components/common/PaginationControl.vue";
import { showNotification } from "@/composables/useNotification";
import { useSalesStore } from "@/stores/Sales";
import { computed, onMounted, ref, watch } from "vue";
import { useProductStore } from "@/stores/productStore";
import router from "@/router";
import SearchBarFilter from "@/components/common/SearchBarFilter.vue";
import { getUserIdFromToken } from "@/utils/userInfo";

const salesStore = useSalesStore();
const showModalDetail = ref(false);
const nameFilter = ref("");
const lastNameFilter = ref("");
const emailFilter = ref("");
const codeFilter = ref("");
const product = ref(null);
const dateFrom = ref(null);
const dateTo = ref(null);
const state = ref(null);
const areFilters = ref(false);

const productStore = useProductStore();
const titlesTable = computed(() => [
  {
    width: "w-4/12",
    title: "Producto",
    dataName: "product",
  },
  {
    width: "w-4/12",
    title: "Nombre y apellido",
    dataName: "user__first_name",
  },
  {
    width: "w-1/6",
    title: "Email",
    dataName: "user__email",
  },
  {
    width: "w-4/12",
    title: "Codigo",
  },
  {
    width: "w-4/12",
    title: "Fecha",
    dataName: "created_at",
  },
  {
    width: "w-4/12",
    title: "Estado",
    dataName: "status",
  },
  {
    width: "w-4/12",
    title: "Total",
    dataName: "total",
  },
  {
    width: "w-4/12",
    title: "Detalle",
  },
  {
    width: "w-4/12",
    title: "Reembolso",
  },
]);

const sortFuncion = async (sortObject) => {
  await salesStore.getSales(
    product.value,
    dateFrom.value,
    dateTo.value,
    1,
    state.value,
    null,
    nameFilter.value,
    lastNameFilter.value,
    emailFilter.value,
    codeFilter.value,
    sortObject
  );
};
onMounted(async () => {
  const userId = getUserIdFromToken(sessionStorage.getItem('token'));
  if (!userId) {
    router.push("/login");
    return;
  }

  await productStore.getAllProducts(userId);
  await salesStore.getSales();
  await salesStore.getStatus();
});
const findFilters = () => {
  return (
    product.value ||
    dateFrom.value ||
    dateTo.value ||
    state.value ||
    nameFilter.value ||
    lastNameFilter.value ||
    emailFilter.value ||
    codeFilter.value
  );
};
const clearFilters = async () => {
  if (!findFilters()) return;
  product.value = null;
  dateFrom.value = null;
  dateTo.value = null;
  state.value = null;
  nameFilter.value = "";
  lastNameFilter.value = "";
  emailFilter.value = "";
  codeFilter.value = "";
  areFilters.value = false;
  await salesStore.getSales(
    product.value,
    dateFrom.value,
    dateTo.value,
    1,
    state.value,
    null,
    nameFilter.value,
    lastNameFilter.value,
    emailFilter.value,
    codeFilter.value
  );
};

const changePage = async (page) => {
  if (page < 1 || page > salesStore.pageCount) return;
  await salesStore.getSales(
    product.value,
    dateFrom.value,
    dateTo.value,
    page,
    state.value,
    null,
    nameFilter.value,
    lastNameFilter.value,
    emailFilter.value,
    codeFilter.value
  );
};
const handleProductSelection = (productObject) => {
  product.value = productObject?.title;
}
const maxDisplayPages = computed(() => {
  return window.innerWidth <= 768 ? 3 : 7;
});

function formatDate(dateString) {
  const date = new Date(dateString);
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}

watch(salesStore.page, async (page, previous) => {
  if (previous !== 0 && page !== 0) {
    await salesStore.getSales(
      product.value,
      dateFrom.value,
      dateTo.value,
      page,
      state.value,
      null,
      nameFilter.value,
      lastNameFilter.value,
      emailFilter.value,
      codeFilter.value
    );
  }
});

const modalDetail = async (id) => {
  await salesStore.getSale(id);
  showModalDetail.value = true;
};

const formattedNumber = computed(() => {
  const numStr = salesStore.total.toString();
  const [integerPart, decimalPart] = numStr.split(".");
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return formattedInteger + (decimalPart ? "." + decimalPart : "");
});

const formatToTwoDecimals = (value) => {
  if (value === null || value === undefined) return "0.00";

  const number = parseFloat(value.replace(/,/g, ""));
  const roundedNumber = Math.round(number * 100) / 100;
  return roundedNumber.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

const refund = async (id) => {
  await salesStore.refund({
    sale_id: JSON.stringify(id),
  });
  if (salesStore.isReady) {
    showNotification("Actualizado correctamente", "success");
    await salesStore.getSales();
  } else {
    showNotification("Ocurrio un error", "error");
  }
};

const isProducer = process.env.VUE_APP_APP_TYPE === "skillper";

const handleSearch = async () => {
  areFilters.value = findFilters();
  await salesStore.getSales(
    product.value,
    dateFrom.value,
    dateTo.value,
    1,
    state.value,
    null,
    nameFilter.value,
    lastNameFilter.value,
    emailFilter.value,
    codeFilter.value
  );
};
</script>

<template>
  <section>
    <div class="mb-20">
      <p class="title-text">Monto recaudado</p>
      <div class="row gap-5 ms-1 flex-column-reverse flex-md-row">
        <div v-if="isProducer" class="col-md-2 rounded p-3 border rounded-lg">
          <p class="fs-5 fw-bold">
            $ {{ formatToTwoDecimals(formattedNumber) }}
          </p>
          <p class="pb-0 mb-0 fw-medium">Total</p>
        </div>
        <div class="col-md-2 rounded p-3 border rounded-lg">
          <p class="fw-bold fs-5">
            $
            {{
              formatToTwoDecimals(salesStore.totalAmountProducer?.toFixed(2))
            }}
          </p>
          <p class="pb-0 mb-0 fw-medium">Por retirar</p>
        </div>
        <div class="col-md-2 rounded p-3 border rounded-lg">
          <p class="fw-bold fs-5">
            $
            {{
              formatToTwoDecimals(
                salesStore.sales?.total_amount_refunded.toFixed(2)
              )
            }}
          </p>
          <p class="pb-0 mb-0 fw-medium">Reembolsado</p>
        </div>
      </div>
    </div>

    <section class="mb-4">
      <div class="main-title">
        <h2 class="fw-semibold">{{ $t("MENU.SALES") }}</h2>
      </div>
      <div class="d-flex w-100 gap-3 flex-column">
        <div class="title-container">
          <h3 class="filters-title">Filtros</h3>
          <div class="buttons-container">
            <button class="btn bg-button text-white" :onclick="clearFilters">
              Limpiar filtros
            </button>
            <div class="w-30">
              <button
                @click="handleSearch"
                class="btn bg-button w-100 text-white"
              >
                Buscar
              </button>
            </div>
          </div>
        </div>
        <div class="row w-100">
          <div class="col-12 col-md-3 margin-bot">
            <SearchBarFilter
              :list="productStore.listProducts.products"
              @item-selected="handleProductSelection"
              prop_name="title"
              label="Producto"
            />
          </div>
          <div class="col-12 col-md-3">
            <TextField
              v-model="nameFilter"
              id="by-name"
              type="text"
              name="userName"
              label="Nombre del usuario"
              placeholder="Buscar"
            />
          </div>
          <div class="col-12 col-md-3">
            <TextField
              v-model="lastNameFilter"
              id="by-lastname"
              type="text"
              name="userLastName"
              label="Apellido del usuario"
              placeholder="Buscar"
            />
          </div>
          <div class="col-12 col-md-3">
            <TextField
              v-model="emailFilter"
              id="by-email"
              type="text"
              name="userEmail"
              label="Email del usuario"
              placeholder="Buscar"
            />
          </div>
          <div class="col-12 col-md-3">
            <TextField
              v-model="codeFilter"
              id="by-code"
              type="text"
              name="saleCode"
              label="Codigo de venta"
              placeholder="Buscar"
            />
          </div>
          <div class="col-12 col-md-3">
            <div class="d-flex gap-4 justify-content-between items-center">
              <div class="w-100">
                <p v-text="'Estado'" class="mb-0" />
                <select
                  class="form-select"
                  aria-label="Default select example"
                  v-model="state"
                >
                  <option value="" selected>Seleccionar</option>
                  <option
                    v-for="(status, index) in salesStore.statusSales"
                    :key="index"
                    :value="status.id"
                  >
                    {{ status.value }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-3">
            <TextField
              v-model="dateFrom"
              id="dateFrom"
              type="date"
              name="dateFrom"
              label="Desde"
              placeholder="From "
            />
          </div>
          <div class="col-12 col-md-3">
            <TextField
              v-model="dateTo"
              id="dateTo"
              type="date"
              name="dateTo"
              label="Hasta"
              placeholder="To "
            />
          </div>
        </div>
      </div>
    </section>

    <div class="table-responsive">
      <DataTable
        :noHaveData="
          !(salesStore.isReady && salesStore?.sales?.results?.length)
        "
        :is-loading="salesStore.isLoading"
        :headers="titlesTable"
        :sortFuncion="sortFuncion"
      >
        <template #body>
          <tr v-for="(item, index) in salesStore.sales?.results" :key="index">
            <td class="p-3" v-text="item.product.title || '-'" />
            <td
              class="p-3"
              v-text="item.user.first_name + ' ' + item.user.last_name || '-'"
            ></td>
            <td class="p-3" v-text="item.user.email || '-'" />
            <td class="p-3" v-text="item?.code || '-'" />
            <td class="p-3" v-text="formatDate(item.created_at) || '-'" />
            <td class="p-3" v-text="item.status?.value || '-'" />
            <td
              class="p-3"
              v-text="(item.total ?? '-') === '-' ? '-' : `$${item.total}`"
            />
            <td class="p-3">
              <p
                class="color-green pb-0 mb-0 underline"
                style="cursor: pointer"
                @click="modalDetail(item.id)"
              >
                Ver detalle
              </p>
            </td>
            <td class="p-3">
              <button
                v-if="
                  item.status?.value == 'Confirmada' ||
                  item.status?.value == 'Completada'
                "
                @click="refund(item.id)"
                class="btn bg-danger text-white"
              >
                Reembolsar
              </button>
              <span v-else>{{ item.status?.value }}</span>
            </td>
          </tr>
        </template>
      </DataTable>
    </div>

    <PaginationControl
      :current-page="salesStore.page"
      :total-pages="salesStore.pageCount"
      :max-display-pages="maxDisplayPages"
      @page-change="changePage"
    />
  </section>

  <Teleport to="body">
    <ModalSales
      @close="showModalDetail = false"
      v-if="showModalDetail"
      :sales="salesStore.sale"
    />
  </Teleport>
</template>

<style scoped>
.refund {
  background: #9c9c9c !important;
}

.table-responsive {
  overflow-x: auto;
}

.bg-button {
  background-color: #202529;
}

.color-green {
  color: #10b981;
}

.underline {
  text-decoration: underline;
}
.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 24px;
}
.margin-bot {
  margin-bottom: 16px;
}
.filters-title {
  font-weight: 600;
  font-size: 20px;
}
.buttons-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}
.buttons-container p {
  margin: 0;
  text-decoration: underline;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}
</style>
