import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';

export const useAuth = () => {
  const router = useRouter();
  const isLoading = ref(false);
  const error = ref(null);
  const customUser = ref(null);

  const isAuthenticated = computed(() => !!sessionStorage.getItem('token'));
  const user = computed(() => customUser.value);

  const handleCustomLogin = async (email, password) => {
    isLoading.value = true;
    error.value = null;

    try {
      const response = await fetch(`${process.env.VUE_APP_API_URL}/api/auth0-login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        sessionStorage.setItem('token', data.access_token);
        sessionStorage.setItem('refresh_token', data.refresh_token);
        const payload = JSON.parse(atob(data.access_token.split('.')[1]));
        customUser.value = {
          sub: payload.sub,
          email: payload.email,
          name: payload.name,
          picture: payload.picture,
          email_verified: payload.email_verified,
          given_name: payload.given_name,
          family_name: payload.family_name,
          nickname: payload.nickname
        };
        return true;
      } else {
        error.value = data.message || 'Error de inicio de sesión';
        return false;
      }
    } catch (err) {
      console.error('Login error:', err);
      error.value = 'Se ha producido un error durante el inicio de sesión';
      return false;
    } finally {
      isLoading.value = false;
    }
  };

  const refreshToken = async () => {
    try {
      const refresh_token = sessionStorage.getItem('refresh_token');
      if (!refresh_token) {
        handleLogout();
        return null;
      }

      const response = await fetch(`${process.env.VUE_APP_API_URL}/api/auth0-refresh-token/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refresh_token }),
      });

      const data = await response.json();

      if (response.ok) {
        sessionStorage.setItem('token', data.access_token);
        return data.access_token;
      } else {
        handleLogout();
        return null;
      }
    } catch (err) {
      console.error('Token refresh error:', err);
      handleLogout();
      return null;
    }
  };

  const handleLogout = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('refresh_token');
    customUser.value = null;
    router.push('/login');
  };

  const getToken = async () => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      handleLogout();
      return null;
    }
    return token;
  };

  return {
    user,
    isAuthenticated,
    isLoading,
    error,
    handleCustomLogin,
    handleLogout,
    getToken,
    refreshToken
  };
};