<script setup>
import DataTable from "@/components/common/DataTable.vue"
import PaginationControl from "@/components/common/PaginationControl.vue"
import { useAffiliateStore } from "@/stores/Affiliate"
import { getUserIdFromToken } from "@/utils/userInfo"
import { computed, onMounted, ref } from "vue"
import { useRouter } from 'vue-router'

const router = useRouter();
const affiliateStore = useAffiliateStore();
const baseURL = process.env.VUE_APP_MARKET_URL;
const currentPage = ref(1);
const productSearch = ref('');

const productTableHeaders = computed(() => [
  {
    width: "w-3/12",
    title: "Nombre del producto",
  },
  {
    width: "w-4/12",
    title: "Comisión",
  },
  {
    width: "w-1/12",
    title: "Precio",
  },
  {
    width: "w-2/12",
    title: "Categoría",
  },
  {
    width: "w-2/12",
    title: "Productor",
  },
  {
    width: "w-2/12",
    title: "Estado",
  },
  {
    width: "w-2/12",
    title: "Link de Afiliado",
  },
]);


onMounted(async () => {
  const token = sessionStorage.getItem('token');
  if (!token) {
    router.push('/login');
    return;
  }

  const userId = getUserIdFromToken(sessionStorage.getItem('token'));
  if (userId) {
    await fetchAffiliateProducts();
  } else {
    router.push('/login');
  }
});

const handlePageChange = async (page) => {
  currentPage.value = page;
  await fetchAffiliateProducts();
};

const affiliateProducts = computed(() => affiliateStore.affiliateProducts);

const filteredProducts = computed(() => {
  return affiliateProducts.value.filter(product =>
    product.product.title.toLowerCase().includes(productSearch.value.toLowerCase())
  );
});

function formatPrice(price) {
  return `$${parseFloat(price).toFixed(2)}`;
}

async function fetchAffiliateProducts() {
  const userId = getUserIdFromToken(sessionStorage.getItem('token'));
  if (userId) {
    await affiliateStore.getAffiliateProducts(userId, currentPage.value);
  } else {
    router.push('/login');
  }
}

function copyLink(link) {
  navigator.clipboard.writeText(link).then(() => {
    console.log('Link copied to clipboard');
  }).catch(err => {
    console.error('Failed to copy link: ', err);
  });
}
</script>

<template>
  <section>
    <div class="mb-4">
      <div class="main-title">
        <h2 class="fw-semibold">{{ $t("MENU.AFFILIATE_PRODUCTS") }}</h2>
      </div>
    </div>

    <DataTable v-if="filteredProducts.length > 0" :headers="productTableHeaders">
      <template #body>
        <tr v-for="(product, index) in filteredProducts" :key="index">
          <td class="p-3" v-text="product.product.title || '-'" />
          <td class="p-3" v-text="product.percentage + '%' || '-'" />
          <td class="p-3" v-text="formatPrice(product.product.price) || '-'" />
          <td class="p-3" v-text="product.product.categories[0] || '-'" />
          <td class="p-3" v-text="product.product.producer || '-'" />
          <td class="p-3" v-text="product.status || '-'" />
          <td class="p-3">
            <button 
              v-if="product.status == 'confirmado'"
              class="btn bg-green text-white" 
              @click="copyLink(baseURL + '/products/detail/' + product.product.id + '?utm_affiliate=' + product.affiliate.utm)"
            >
              Copiar link
            </button>
          </td>
        </tr>
      </template>
    </DataTable>

    <PaginationControl
      v-if="filteredProducts.length && affiliateStore.pageCount > 1"
      :current-page="currentPage"
      :total-pages="affiliateStore.pageCount"
      @page-change="handlePageChange"
    />
  </section>
</template>