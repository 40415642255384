<script setup>
import DataTable from "@/components/common/DataTable.vue";
import TextField from "@/components/common/TextField.vue";
import PaginationControl from "@/components/common/PaginationControl.vue";
import { useSalesStore } from "@/stores/Sales";
import { computed, onMounted, ref } from "vue";
import SearchBarFilter from "@/components/common/SearchBarFilter.vue";
import { useProductStore } from "@/stores/productStore";
import router from "@/router";
import { getUserIdFromToken } from "@/utils/userInfo";

const salesStore = useSalesStore();
const currentPage = ref(1);
const productStore = useProductStore();
const product = ref(null);
const dateFrom = ref(null);
const dateTo = ref(null);
const state = ref(null);
const userName = ref("");
const userLastname = ref("");
const userEmail = ref("");
const salesCode = ref("");
const data = ref([
  {
    product: "Product 1",
    name: "Hugo perez",
    email: "hugo@hugo",
    phone: "123456789",
    date: "2022-01-01",
  },
  {
    product: "Product 2",
    name: "Hugo perez",
    email: "hugo@hugo",
    phone: "123456789",
    date: "2022-01-01",
  },
]);

const titlesTable = computed(() => [
  {
    width: "w-4/12",
    title: "Producto",
  },
  {
    width: "w-4/12",
    title: "Nombre y apellido",
  },
  {
    width: "w-1/6",
    title: "Email",
  },
  {
    width: "w-1/6",
    title: "Codigo",
  },
  {
    width: "w-4/12",
    title: "Fecha",
  },
  {
    width: "w-2/12",
    title: "Total de Venta",
  },
  {
    width: "w-2/12",
    title: "Comisión Obtenida",
  },
  {
    width: "w-2/12",
    title: "Estado de Venta",
  },
]);

const handleProductSelection = (productObject) => {
  product.value = productObject?.title;
};

onMounted(async () => {
  const userId = getUserIdFromToken(sessionStorage.getItem("token"));
  if (!userId) {
    router.push("/login");
    return;
  }
  await productStore.getAllProducts(userId);
  await salesStore.getSales();
  await salesStore.getStatus();
});

const handlePageChange = async (page) => {
  currentPage.value = page;
  await salesStore.getSales(
    product.value,
    dateFrom.value,
    dateTo.value,
    page,
    state.value
  );
};

function formatDate(dateString) {
  const date = new Date(dateString);
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}

const search = async () => {
  currentPage.value = 1; // Reset to first page when searching
  await salesStore.getSales(
    product.value,
    dateFrom.value,
    dateTo.value,
    1,
    state.value,
    null,
    userName.value,
    userLastname.value,
    userEmail.value,
    salesCode.value
  );
};
</script>

<template>
  <section>
    <div class="mb-20">
      <p class="title-text">Monto recaudado</p>
      <div class="row gap-5 ms-1">
        <div class="col-md-2 rounded p-3 border rounded-lg">
          <p class="fs-5 fw-bold">$ {{ salesStore.total?.toFixed(2) }}</p>
          <p class="pb-0 mb-0 fw-medium">Total</p>
        </div>
        <div class="col-md-2 rounded p-3 border rounded-lg">
          <p class="fw-bold fs-5">
            $ {{ salesStore.totalAmountAffiliate?.toFixed(2) }}
          </p>
          <p class="pb-0 mb-0 fw-medium">Por retirar</p>
        </div>
      </div>
    </div>

    <section class="mb-4">
      <div class="d-flex w-100 gap-3 flex-column">
        <div class="title-container">
          <h2 class="fw-semibold">{{ $t("MENU.SALES") }}</h2>
          <div class="buttons-container">
            <div class="w-30">
              <button @click="search" class="btn bg-button w-100 text-white">
                Buscar
              </button>
            </div>
          </div>
        </div>
        <div class="d-flex w-100 gap-4">
          <div class="row w-100">
            <div class="col-12 col-md-3 margin-bot">
              <SearchBarFilter
                :list="productStore?.listProducts?.products"
                @item-selected="handleProductSelection"
                label="Producto"
                prop_name="title"
              />
            </div>
            <div class="col-12 col-md-3">
              <TextField
                v-model="userName"
                id="userName"
                type="text"
                name="userName"
                label="Nombre del usuario"
                placeholder="Buscar"
              />
            </div>
            <div class="col-12 col-md-3">
              <TextField
                v-model="userLastname"
                id="userLastname"
                type="text"
                name="userLastname"
                label="Apellido del usuario"
                placeholder="Buscar"
              />
            </div>
            <div class="col-12 col-md-3">
              <TextField
                v-model="userEmail"
                id="userEmail"
                type="text"
                name="userEmail"
                label="Email del usuario"
                placeholder="Buscar"
              />
            </div>
            <div class="col-12 col-md-3">
              <TextField
                v-model="salesCode"
                id="salesCode"
                type="text"
                name="salesCode"
                label="Codigo de venta"
                placeholder="Buscar"
              />
            </div>
            <div class="col-12 col-md-3">
              <TextField
                v-model="dateFrom"
                id="dateFrom"
                type="date"
                name="dateFrom"
                label="Desde"
                placeholder="From "
              />
            </div>
            <div class="col-12 col-md-3">
              <TextField
                v-model="dateTo"
                id="dateTo"
                type="date"
                name="dateTo"
                label="Hasta"
                placeholder="To "
              />
            </div>
            <div class="col-12 col-md-3">
              <div class="d-flex gap-4 justify-content-between items-center">
                <div class="w-100">
                  <p v-text="'Estado'" class="mb-0" />
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    v-model="state"
                  >
                    <option value="" selected>Seleccionar</option>
                    <option
                      v-for="(status, index) in salesStore.statusSales"
                      :key="index"
                      :value="status.id"
                    >
                      {{ status.value }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <DataTable :noHaveData="data?.length === 0" :headers="titlesTable">
      <template #body>
        <tr v-for="(item, index) in salesStore.sales?.results" :key="index">
          <td class="p-3" v-text="item.product.title || '-'" />
          <td
            class="p-3"
            v-text="item.user.first_name + ' ' + item.user.last_name || '-'"
          />
          <td class="p-3" v-text="item.user.email || '-'" />
          <td class="p-3" v-text="item.code || '-'" />
          <td class="p-3" v-text="formatDate(item.created_at) || '-'" />
          <td class="p-3" v-text="'$' + item.total || '0'" />
          <td class="p-3" v-text="'$' + item.total_affiliate || '0'" />
          <td class="p-3" v-text="item?.status?.value || '-'" />
        </tr>
      </template>
    </DataTable>

    <PaginationControl
      v-if="salesStore.sales?.results?.length && salesStore.pageCount > 1"
      :current-page="currentPage"
      :total-pages="salesStore.pageCount"
      @page-change="handlePageChange"
    />
  </section>
</template>
<style scoped>
.bg-button {
  background-color: #202529;
}
.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 24px;
}
.filters-title {
  font-weight: 600;
  font-size: 20px;
}
.buttons-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}
.buttons-container p {
  margin: 0;
  text-decoration: underline;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}
.margin-bot {
  margin-bottom: 16px;
}
</style>
