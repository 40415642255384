// src/router/index.js
import QuestionaireForm from "@/components/forms/QuestionaireForm.vue"
/* import AffiliateOffersList from "@/views/AffiliateOffersList.vue"
 */import AffiliateProducts from "@/views/AffiliateProducts.vue"
import AffiliateSalesList from "@/views/AffiliateSalesList.vue"
import AffiliatesList from "@/views/AffiliatesList.vue"
/* import AffiliateSubscriptions from "@/views/AffiliateSubscriptions.vue"
 */import AllProducts from "@/views/AllProducts.vue"
import Home from "@/views/HomeMain.vue"
import LoginView from "@/views/LoginView.vue"
import NotFound from "@/views/NotFound.vue"
import OffersList from "@/views/OffersList.vue"
import PayoutsList from "@/views/PayoutsList.vue"
import AddProduct from "@/views/Product/AddProduct.vue"
import EditProduct from "@/views/Product/EditProduct.vue"
import AppProfile from "@/views/Profile.vue"
import ReportsView from "@/views/ReportsView.vue"
import SalesList from "@/views/SalesList.vue"
import ServerError from "@/views/ServerError.vue"
import SubscriptionsProducer from "@/views/SubscriptionsProducer.vue"
import { createRouter, createWebHistory } from "vue-router"

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: { requiresAuth: false }
  },
  {
    path: "/",
    name: "dashboard-home",
    component: Home,
    meta: { requiresAuth: true },
    children: [
      {
        path: "/",
        name: "home",
        component:
          process.env.VUE_APP_APP_TYPE === "skillper"
            ? QuestionaireForm
            : AffiliateProducts,
        meta: { requiresAuth: true },
      },
      {
        path: "/products",
        name: "products",
        component: AllProducts,
        meta: { requiresAuth: true },
      },
      {
        path: "/sales",
        name: "sales",
        component: SalesList,
        meta: { requiresAuth: true },
      },
      {
        path: "/affiliate-sales",
        name: "affiliate-sales",
        component: AffiliateProducts,
        meta: { requiresAuth: true },
      },
      {
        path: "/affiliate-products",
        name: "affiliate-products",
        component: AffiliateSalesList,
        meta: { requiresAuth: true },
      },
      {
        path: "/affiliate-subscriptions",
        name: "affiliate-subscriptions",
        component: SubscriptionsProducer,
        meta: { requiresAuth: true },
      },
      {
        path: "/addProduct",
        name: "AddProduct",
        component: AddProduct,
        meta: { requiresAuth: true },
      },
      {
        path: "/edit-product",
        name: "editProduct",
        component: EditProduct,
        meta: { requiresAuth: true },
      },
      {
        path: "/profile",
        component: AppProfile,
        meta: { requiresAuth: true },
      },
      {
        path: "/reports",
        component: ReportsView,
        meta: { requiresAuth: true },
      },
      {
        path: "/affiliates/:id?",
        name: "affiliates",
        component: AffiliatesList,
        meta: { requiresAuth: true },
      },
      {
        path: "/payouts",
        component: PayoutsList,
        meta: { requiresAuth: true },
      },
      {
        path: "/offers",
        component: OffersList,
        meta: { requiresAuth: true },
      },
      {
        path: "/subscriptions-producer",
        component: SubscriptionsProducer,
        meta: { requiresAuth: true },
      },
      {
        path: "/affiliate-offers",
        component: OffersList,
        meta: { requiresAuth: true },
      },
      {
        path: "/404",
        name: "NotFound",
        component: NotFound,
        meta: { requiresAuth: true }
      },
      {
        path: "/500",
        name: "ServerError",
        component: ServerError,
        meta: { requiresAuth: true }
      },
      {
        path: '/:pathMatch(.*)',
        name: "NotFound",
        redirect: {name: "NotFound"},
        meta: { requiresAuth: true }
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem('token');
  
  if (to.path === '/login') {
    if (token) {
      next('/');
    } else {
      next();
    }
    return;
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!token) {
      next('/login');
      return;
    }

    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      if (payload.exp * 1000 < Date.now()) {
        sessionStorage.removeItem('token');
        next('/login');
        return;
      }
    } catch (error) {
      sessionStorage.removeItem('token');
      next('/login');
      return;
    }
  }

  next();
});

export default router;